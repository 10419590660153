.featured_items_container {
  .featured_item {
    position: relative;
    display: flex;
    align-items: center;

    .item_text {
      display: inline-block;
      width: 50%;
      padding: 6.5em;

      p {
        font-size: 1em;
        padding-top: 1em;
      }

      h2 {
        font-size: 2em;
      }
    }

    img.food {
      width: 50%;
    }
  }

  .small_image_1 {
    position: absolute;
    top: 10%;
    right: 60%;
  }
  .small_image_2 {
    position: absolute;
    bottom: 13%;
    right: 90%;
  }
}
