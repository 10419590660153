@import "../../App.scss";

.thanks_img {
  margin: 6em auto;
  width: 100%;
}

.thanks_wrapper {
  position: relative;

  h1 {
    color: $details_main_color;
    font-size: 3.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: -7px 0px 180px -18px rgba(0, 0, 0, 0.35);
  }
}
