@import "../../../../App.scss";

.basket_item {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1rem;
  margin-bottom: 2rem;

  img {
    width: 100%;
  }

  .basket_item_description {
    font-size: 0.8rem;
  }

  .quantity {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    input[type="number"] {
      width: 2.5rem;
      height: 1.5rem;
      background-color: $details-secondary-color;
      border: none;
      margin: 1rem 0;
      padding: 0.2rem;
    }
  }
}

.basket_item_info {
  position: relative;
  padding: 1.3rem;
}

.serving_info {
  display: flex;
  margin-bottom: 1rem;
  margin-top: -1.5rem;

  p {
    margin-right: 1rem;
  }
}

.remove {
  text-align: right;

  .remove_btn {
    @include small_yellow_btn;
  }
}

.update_btn {
  @include small_yellow_btn;
  margin: 1rem 0;
}
