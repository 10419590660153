@import "../../App.scss";

.footer {
  position: relative;
  height: 209px;
  background: url(../../images/footer_background.jpg) no-repeat;

  .footer_container {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3em 5em;

    .list {
      color: $details-main-color;

      li {
        padding: 0.1em;
        font-size: 0.7em;
      }
    }

    ul.list > h4 {
      color: $details-secondary-color;
      padding-bottom: 0.5em;
    }
  }
}
