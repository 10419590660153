@import "../../../App.scss";

.basket {
  background-color: black;
  color: white;
  padding: 2.5rem;

  .basket_header {
    h5 {
      @include title;
      margin-left: 2rem;
      // padding: 0.3rem 1rem;
    }
  }

  .basket_header::before {
    content: url(../../../images/cart.svg);
  }

  .basket_flex {
    display: flex;
    justify-content: space-between;
    // background-color: rgb(136, 136, 104);
    // margin-right: 1rem;
  }
}
