@import "../../App.scss";

.nav_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 55%;
  color: $details-main-color;
  width: 45%;
  max-width: 500px;
  padding: 1em;
  margin: 1em 0;
  text-transform: uppercase;
  font-size: 1em;
  z-index: 1;

  a {
    color: white;
  }

  li.hover:hover {
    color: yellow;
  }
}

.basket_quantity {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $details_secondary_color;
  border: 1px solid $details-main-color;
  margin: 0 1rem 1rem -2rem;
  text-align: center;
  color: black;
}

.basket_icon {
  padding: 0 1rem;
}
