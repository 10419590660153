@import "../../../App.scss";

.ingredients {
  position: relative;
  background: url(../../../images/cheese.svg) no-repeat top 5% left 98%,
    url(../../../images/vegetables.svg) no-repeat top 50% left 92%,
    url(../../../images/groceries.svg) no-repeat top 60% left 60%,
    url(../../../images/carrot.svg) no-repeat bottom 5% left 75%,
    url(../../../images/ing_background.jpg) no-repeat;
  height: 843px;
  color: $details-main-color;

  .ingredients_content {
    position: absolute;
    top: 20%;
    left: 60%;
    width: 40%;
  }

  .ingredients_header,
  .grid_container {
    margin-left: 4em;
  }

  .grid_container {
    display: grid;
    grid-template-columns: 6em 1fr;
    gap: 1.3em 0.25em;
  }
}
.ing_title {
  @include title;
  margin: 0.5rem 0;
}
.separator {
  border-bottom: 1px solid grey;
  margin: 1rem 0 1rem 0;
}
