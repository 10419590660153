@import "../../App.scss";

.cart {
  padding: 3rem 0 3rem 3rem;

  label {
    display: inline-block;
    margin-bottom: 1rem;
    color: $details-secondary-color;
    font-size: 0.8rem;
    font-weight: 800;
  }

  input,
  select {
    margin-bottom: 1rem;
  }

  input {
    border: 1px solid $details-secondary-color;
    padding: 0.4rem;
    font-size: 0.9rem;
  }

  select {
    background-color: $details-secondary-color;
    border: none;
  }

  .wide {
    width: 80%;
  }

  .narrow {
    width: 37.6%;
    margin-right: 1rem;
  }

  .cart_form {
    width: 90%;
  }

  .form_header {
    color: $details-secondary-color;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  span.format {
    display: inline-block;
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    color: rgb(116, 114, 114);
  }

  .yellow_button {
    @include yellow_btn;
    margin: 1rem 0;
  }
}

.fancy_background {
  background: url(../../images/carrot.svg) no-repeat top 0 left 98%,
    url(../../images/cheese.svg) no-repeat top 0 left 28%,
    url(../../images/spaghetti.svg) no-repeat top 50% left 38%,
    url(../../images/vegetables.svg) no-repeat top 28% left 25%,
    url(../../images/juice.svg) no-repeat top 95% left 90%,
    url(../../images/cheese.svg) no-repeat top 75% left 13%;
  min-height: 700px;

  .container {
    display: grid;
    grid-template-columns: 35% 65%;
  }
}
