@import "../../App.scss";

.header {
  color: $details-main-color;

  .header_container {
    background: url(../../images/header_background.jpg) no-repeat;
    height: 684px;
    position: relative;
    padding: 2em;
    text-align: left;

    .header_button {
      @include button;
    }

    .header_text_wrapper {
      position: absolute;
      top: 40%;
      left: 48%;
      width: 40vw;

      h1.header_text {
        font-size: 2.5em;
      }
    }
  }
}
