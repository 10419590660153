$details-main-color: #fff;
$details-secondary-color: #ffc700;

@mixin button {
  width: 173px;
  height: 38px;
  font-size: 0.8em;
  margin: 1em 0;
  background-color: transparent;
  color: $details-main-color;
  border: 2px solid $details-secondary-color;
  text-transform: uppercase;
  cursor: pointer;
}

@mixin yellow_btn {
  width: 173px;
  height: 38px;
  background-color: $details-secondary-color;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 2px solid $details-secondary-color;
  font-size: 0.9em;
  font-weight: 800;
  text-transform: uppercase;
  cursor: pointer;
}

@mixin small_yellow_btn {
  width: 4rem;
  height: 1.3rem;
  background-color: $details-secondary-color;
  color: black;
  font-weight: 600;
  border: none;
}

@mixin title {
  color: $details-main-color;
  background-color: $details-secondary-color;
  padding: 0.5rem;
  display: inline-block;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  max-width: 1440px;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}
